import Client from './Client'

class OuterApi extends Client {
  async getList(payload) {
    let params = { page: payload.page };
    if (payload.start_at) {
      params.start_at = payload.start_at
      params.end_at = payload.end_at
    }
    const { data, meta: { last_page: lastPage } } = await this.get(`/${payload.source}`, params)

    return { data, lastPage }
  }

  async getDetail({ source, id }) {
    const { data } = await this.get(`/${source}/${id}`)

    return data
  }
}

export default new OuterApi({
  baseURL: '/:orgCode/liff/outer',
})
