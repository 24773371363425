<template>
  <div class="page">
    <div class="s-orders-nav">
      <div class="s-basis-1/4">
        <button class="s-orders-btn ml-3 pl-3 pr-3" @click="back">返回</button>
      </div>
      <div class="text-center s-basis-1/2">{{ getConfig('form_title') }}</div>
    </div>
    <p v-if="fetching"><Loading /></p>
    <template v-else>
      <DetailSection class="m-3" icon="info" title="資訊" v-if="fieldSetting.length > 0">
        <table class="s-text-sm s-w-full">
          <template v-if="fieldSetting.length > 0">
            <tr :key="i" v-for="(field, i) in fieldSetting">
              <td class="s-font-semibold s-text-primary-text">{{ field.value }}</td>
              <td class="s-text-right s-text-black">{{ object[field.key] }}</td>
            </tr>
          </template>
        </table>
      </DetailSection>
      <DetailSection class="m-3" icon="list" title="明細" v-if="itemFieldSetting.length > 0">
        <table class="s-text-sm s-w-full">
          <thead>
            <tr class="s-font-semibold">
              <template v-if="itemFieldSetting.length > 0">
                <th v-for="(field, i) in itemFieldSetting" :key="i" :class="itemThClass(i)">
                    {{ field.value }}
                </th>
              </template>
            </tr>
          </thead>
          <tbody>
            <tr :key="i" v-for="(item, i) in items">
              <template v-if="itemFieldSetting.length > 0">
                <td v-for="(field, j) in itemFieldSetting" :key="j" :class="itemTdClass(j)">
                  {{ item[field.key] }}
                </td>
              </template>
            </tr>
          </tbody>
        </table>
      </DetailSection>
    </template>
  </div>
</template>

<script>
  import DetailSection from '@/components/Page/Liff/Outer/DetailSection'
  import outerMixin from "@/mixins/liff/outer";
  import outerApi from "@/apis/liff/v2/outer";

  export default {
    mixins: [outerMixin],
    components: { DetailSection },
    data: () => ({
      object: {},
      from: null,
      fetching: true,
    }),
    mounted () {
      this.fetch().then(() => {
        this.fetching = false
      })
    },
    computed: {
      items () {
        return this.object.items || []
      },
      fieldSetting() {
        return this.getConfig('field_setting.detail.object').sort((a, b) => a.order - b.order);
      },
      itemFieldSetting() {
        return this.getConfig('field_setting.detail.item').sort((a, b) => a.order - b.order);
      },
      page() {
        return this.$route.params.source + "_page";
      },
    },
    methods: {
      getConfig(key) {
        return this.getModuleConfig(this.page + '.' + key);
      },
      async fetch () {
        this.object = await outerApi.getDetail({ id: this.$route.params.id, source: this.page })
      },
      back () {
        if (this.from && this.from.fullPath !== '/') {
          return this.$router.push(this.from)
        }

        this.$router.push({
          name: 'LiffOuterIndex',
        })
      },
      itemThClass(i) {
        if (i == 0) {
          return 's-text-primary-text'
        } else {
          return 's-text-right s-min-w-10 s-text-black'
        }
      },
      itemTdClass(i) {
        if (i == 0) {
          return 's-text-primary-text'
        } else {
          return 's-text-right s-text-black'
        }
      },
    },
    beforeRouteEnter(_, from, next) {
      next((vm) => {
        vm.from = from
      })
    },
  }
</script>

<style scoped>
  .s-orders-nav {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    display: flex;
    padding: 0.75rem 0;
    border-bottom: solid 1px var(--s-gray-lighter);
    background-color: var(--s-white);
  }

  .s-orders-btn {
    color: var(--s-gray);
    border: 1px solid var(--s-gray);
    text-decoration: none;
    border-radius: 8px;
    font-size: 14px;
    line-height: 1.5;
    padding: 2px 6px;
  }

  table th,
  table tr:not(:last-child) td {
    padding-bottom: 1.25rem;
  }

  .s-text-primary-text {
    color: var(--liff-primary_text_color);
  }

  .s-text-black {
    color: var(--s-gray-darker);
  }
</style>
